body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #ffffff;
  line-height: 1.618;
}

html {
  background-color: #ffffff;
  box-sizing: border-box;
  font-size: 12px;
}

code {
  background-color: rgba(0, 0, 0, 0.1);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
